import React, {useContext, useEffect, useState} from "react";
import {Button, Layout, Row} from "antd";
import {Header} from "antd/es/layout/layout";
import {NavBar} from "../components/NavBar";
import {addUser, assignRoleMembers, deleteUser, getRoles, getUserList, toggleUser} from "../utils/api/AxiosUtils";
import {User, UserContext} from "../contexts/UserInfoContext";
import {ColumnsType} from "antd/es/table";
import {useTranslation} from "react-i18next";
import {TitleText} from "../components/TitleText";
import {toast} from "react-toastify";
import {AddUserModal} from "../modals/AddUserModal";
import {UserTable} from "../components/UserTable";
import {hasPerms, UIGuard} from "../utils/PermGuard";
import EFeature from "../enums/EFeature";
import EFeatureScope from "../enums/EFeatureScope";
import RolesType from "../models/RolesType";
import {isIdInArr} from "../utils/CommonUtils";
import {useMsalWrapper} from "../contexts/MSALContext";

export const UsersPage = () => {

    const [adminRole, setAdminRole] = useState<RolesType>()

    const [userList, setUserList] = useState<User[]>([])

    const [addModal, setAddModal] = useState<boolean>(false);

    const [addEmail, setAddEmail] = useState<string>('')

    const [firstName, setFirstName] = useState<string>()

    const [lastName, setLastName] = useState<string>()

    const [emailValid, setEmailValid] = useState<boolean>(true)

    const [nameValid, setNameValid] = useState<boolean>(true)

    const [selectedUsers, setSelectedUsers] = useState<User[]>([])

    const [page, setPage] = useState<number>(0)

    const [totalPages, setTotalPages] = useState<number>(0)

    const { acquireToken } = useMsalWrapper()

    const {t} = useTranslation();

    const user = useContext(UserContext)

    const columns: ColumnsType<User> = [
        {
            title: 'Name',
            dataIndex: 'fullName',
            render: (text, record) => (
                <span>{record.firstName} {record.lastName}</span>
            )
        },
        {
            title: 'Email',
            dataIndex: 'email'
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            width: hasPerms(user?.permissions ? user.permissions : [], {feature: EFeature.Roles, scope: EFeatureScope.Grant}) ? 130 : 5,
            render: (_, record) => {
                const userIsAdmin = adminRole?.members ? isIdInArr(record.token, adminRole.members) : false
                return (<UIGuard routePerms={{ feature: EFeature.Users, scope: EFeatureScope.Delete }}>
                    <div className="flex justify-center space-x-2" >
                        <div className="w-[20px]"><button onClick={() => onClickDelete(record)}><img className="rounded" width={20}
                                                                                src={process.env.PUBLIC_URL + "/icons/trash.svg"}
                                                                                alt={'delete'}/></button></div>
                        <UIGuard routePerms={{ feature: EFeature.Roles, scope: EFeatureScope.Grant}}>
                            <Button shape="round" size="small" onClick={() => toggleAdmin(record.token, userIsAdmin)}
                                    style={{ backgroundColor: userIsAdmin ? 'var(--red)' : '', color: userIsAdmin ? 'white' : 'black'}}
                            >
                                {t("Admin")}
                            </Button>
                        </UIGuard>
                    </div>
                </UIGuard>)
            },
        },
    ]

    const toggleAddModal = () => { setAddModal(!addModal) }

    const toggleAdmin = async (userId: string, isAdmin?: boolean) => {
        const adminCode = process.env.REACT_APP_ADMIN_ROLE_CODE

        if (!adminCode) return

        if (!adminRole) return

        let members = adminRole.members
        if (isAdmin) {
            members = adminRole.members.filter(member => member !== userId)
        } else { members.push(userId) }

        const token = await acquireToken()
        if (!token) return

        const response = await assignRoleMembers(token, adminCode, members)

        if (!response) {
            toast.error(`Failed to ${isAdmin ? 'unassign' : 'assign'} admin`, {position: "bottom-center", autoClose: 5000, closeOnClick: true})
            return
        }

        fetchAdminRole()
    }

    const onClickDelete = (user: User) => {
        removeUser(user).then(() => {
            fetchUsers().then(() =>
                toast.success('Deleted user successfully', {position: "bottom-center", autoClose: 5000, closeOnClick: true}))
        })
    }

    const isEmailValid = (email: string): boolean => {
        const allowedDomains = process.env.REACT_APP_ALLOWED_EMAILS
        if (!allowedDomains) return false
        const domains = JSON.parse(allowedDomains)
        const domain = email.split('@')[1]
        return domains.includes(domain)
    }

    const fetchRoles = async () => {
        const token = await acquireToken()
        if (!token) return

        const response = await getRoles(token)

        if (!response) return

        return response.data.roles
        // setRoleList(response.data.roles)
    }

    const fetchAdminRole = async () => {
        const adminCode = process.env.REACT_APP_ADMIN_ROLE_CODE

        if (!adminCode) return

        const roles: RolesType[] = await fetchRoles()

        if (!roles) return

        const role = roles.find(role => role.code === adminCode)

        if (!role) return

        setAdminRole(role)

        console.log(role)
    }

    const onAddUser = async () => {
        const isValid = isEmailValid(addEmail)
        setEmailValid(isValid)

        if (firstName && lastName) {
            setNameValid(true)
        } else setNameValid(false)

        if (!nameValid || !emailValid) return

        await postUser()
    }

    const postUser = async () => {
        const token = await acquireToken()
        if (!token) return

        if (!(firstName && lastName && addEmail)) return
        setAddModal(false)

        const response = await addUser(token, firstName, lastName, addEmail)


        if (!response) {
            toast.error('Failed to add user', {position: "bottom-center", autoClose: 5000, closeOnClick: true})
            return
        }
        await fetchUsers()
        toast.success('Added user successfully', {position: "bottom-center", autoClose: 5000, closeOnClick: true})
    }

    const removeUser = async (user: User) => {
        const token = await acquireToken()
        if (!token) return

        const response = await deleteUser(token, user.token)

        if (!response) {
            toast.error(`Failed to delete user ${user.email}`, {position: "bottom-center", autoClose: 5000, closeOnClick: true})
            return
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchUsers = async () => {
        const token = await acquireToken()
        if (!token) return

        const response = await getUserList(token, page, 10)

        if (!response) return

        setTotalPages(response.data.pagination.total)

        const userData = response.data.users

        userData.forEach((user: User) => {
            user.key = user.token
        })
        setUserList(userData)
    }

    const runOnSelectedUsers = async (method: (user: User) => Promise<void>, successMessage: string) => {
        try {
            await Promise.all(selectedUsers.map(method))
            toast.success(successMessage, {
                position: "bottom-center",
                autoClose: 5000,
                closeOnClick: true
            })
            await fetchUsers()
        } catch (error) {
            console.error(error)
        }
    }

    const deleteUsers = async () => {
        if (selectedUsers.length < 0) return
        await runOnSelectedUsers(async (user) => await removeUser(user), "Deleted users successfully")
    }

    const onToggle = async (disable?: boolean) => {
        if (selectedUsers.length < 0) return

        const toggleStat = disable ? 'Disabled' : 'Enabled'
        await runOnSelectedUsers(async (user) => await toggleUserStatus(user, disable), `${toggleStat} users successfully`)
    }

    const toggleUserStatus = async (user: User, disable?: boolean) => {
        const token = await acquireToken()
        if (!token) return

        const toggleStat = disable ? 'disable' : 'enable'

        const response = await toggleUser(token, user.token, disable)

        if (!response) {
            toast.error(`Failed to ${toggleStat} user ${user.email}`, {position: "bottom-center", autoClose: 5000, closeOnClick: true})
            return
        }
    }


    useEffect(() => {
        fetchUsers()
        fetchAdminRole()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])


    const rowSelection = {
        onChange: (selectedRowKeys: any, selectedRows: User[]) => {
            setSelectedUsers(selectedRows)
        },
        getCheckboxProps: (record: User) => ({
            name: record.firstName,
        }),
    };

    return (
        <Layout className="frame">
            <Header id="search-header">
                <NavBar/>
            </Header>


            <div className="pageBody">
                <TitleText text={t("User management")}/>
                <div className="flex flex-col">
                    <Row className="card-buttons mb-3" wrap={false}>
                        <UIGuard routePerms={{ feature: EFeature.Users, scope: EFeatureScope.Create}}>
                            <Button shape="round" size="small" style={{color: 'black'}} onClick={toggleAddModal}
                            >
                                {t("Add user")}
                            </Button>
                        </UIGuard>
                        <UIGuard routePerms={{ feature: EFeature.Users, scope: EFeatureScope.Update}}>
                            <Button shape="round" size="small" style={{color: 'black'}} onClick={() => onToggle()}
                            >
                                {t("Enable user")}
                            </Button>
                        </UIGuard>
                        <UIGuard routePerms={{ feature: EFeature.Users, scope: EFeatureScope.Delete}}>
                            <Button shape="round" size="small" style={{backgroundColor: 'var(--red)', color: 'white'}} onClick={deleteUsers}
                            >
                                {t("Remove user")}
                            </Button>
                        </UIGuard>
                        <UIGuard routePerms={{ feature: EFeature.Users, scope: EFeatureScope.Update}}>
                            <Button shape="round" size="small" style={{backgroundColor: 'var(--red)', color: 'white'}} onClick={() => onToggle(true)}
                            >
                                {t("Disable user")}
                            </Button>
                        </UIGuard>
                    </Row>
                    <UserTable rowSelection={rowSelection} columns={columns} userList={userList} pagination={{ defaultPageSize: 10, simple: true, total: totalPages, onChange: (page) => setPage(page - 1) }}/>
                </div>
            </div>
            <AddUserModal addModal={addModal} setAddModal={setAddModal} firstName={firstName}
                          setFirstName={setFirstName} lastName={lastName} setLastName={setLastName}
                          email={addEmail} setEmail={setAddEmail} emailValid={emailValid}
                          nameValid={nameValid} addUser={onAddUser} />
        </Layout>
    )
}
