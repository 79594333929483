import {useEffect, useState} from "react";
import {User, UserContext} from "../contexts/UserInfoContext";
import {AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal} from "@azure/msal-react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {InteractionStatus} from "@azure/msal-browser";
import {getUser} from "../services/UserService";

import * as gtag from "../infrastructure/gtag";
import {MaintenancePage} from "./MaintenancePage";
import {AccountInfoContext} from "../contexts/AccountInfoContext";
import {LoginPage} from "./LoginPage";
import {IdTokenContext} from "../contexts/IdTokenContext";
import {useMsalWrapper} from "../contexts/MSALContext";

export const RootPage = () => {
    const [user, setUser] = useState<User | null>(null)
    const [token, setToken] = useState<string>("")
    const { instance, inProgress, accounts } = useMsal()
    const { acquireToken } = useMsalWrapper()


    const maintenance = process.env.REACT_APP_MAINTENANCE_MODE === "true";
    const location = useLocation();
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();

    // get user profile, set user details & token
    useEffect(() => {
        const getUserProfile = async () => {
            if (isAuthenticated && inProgress === InteractionStatus.None) {
                // const response = await instance.acquireTokenSilent({ account: accounts[0], scopes: []})
                //
                // const token = response.idToken

                const token = await acquireToken()

                if (!token) return

                try {
                    const user: any = await getUser(token)
                    setUser(user)
                    setToken(token)
                    console.log(user)
                    return
                } catch (error: any) {
                    console.error(error)
                    navigate("/backend-error")
                }
            }
        }
        if (accounts.length === 0) return

        getUserProfile()
    }, [accounts, inProgress, instance, isAuthenticated, navigate])

    // Google Analytics
    useEffect(() => {
        gtag.pageview(location.pathname);
    }, [location])

    return (
        <>
            {maintenance ? (
                <MaintenancePage />
            ) : (
                <AccountInfoContext.Provider value={accounts[0]}>
                    <UnauthenticatedTemplate>
                        <LoginPage />
                    </UnauthenticatedTemplate>

                    <AuthenticatedTemplate>
                        <IdTokenContext.Provider value={token}>
                            <UserContext.Provider value={user}>
                                <Outlet />
                            </UserContext.Provider>
                        </IdTokenContext.Provider>
                    </AuthenticatedTemplate>
                </AccountInfoContext.Provider>

            )}
        </>
    )
}
