import { Row, Col, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useRouteError } from "react-router-dom";

const { Text, Title } = Typography;

export const ErrorPage = () => {
    const error = useRouteError() as any;
    const { t } = useTranslation();

    return (
        <Row>
            <Col className="search-error">
                <Row>
                    <img src={process.env.PUBLIC_URL + "/icons/sad.svg"} alt="" />
                </Row>
                <Row>
                    <Title level={3}>{error?.statusText || error?.message}</Title>
                </Row>
                <Row>
                    <Text>{t("The page you requested doesn't seem to exist")}</Text>
                </Row>
                <Row>
                    <Text>{"Please return to the homepage and try again"}</Text>
                </Row>
            </Col>
        </Row>
    );
};
