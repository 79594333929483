import React, { createContext, useState, useContext, ReactNode } from 'react'
import { PublicClientApplication, AccountInfo } from '@azure/msal-browser'
import {loginRequest} from "../infrastructure/authConfig";

interface MsalWrapperContextType {
    inProgress: boolean,
    safeLogin: () => Promise<void>,
    getAllAccounts: () => AccountInfo[] | undefined,
    acquireTokenSilent: (tokenReq: { scopes: string[], account: AccountInfo }) => Promise<any>,
    acquireTokenPopup: (tokenReq: { scopes: string[], account: AccountInfo }) => Promise<any>,
    acquireToken: () => Promise<string | undefined>
}

const MsalWrapperContext = createContext<MsalWrapperContextType | undefined>(undefined)

interface MsalWrapperProps {
    children: ReactNode,
    msalInstance: PublicClientApplication // Pass the MSAL instance as a prop
}

export const MsalWrapperProvider: React.FC<MsalWrapperProps> = ({ children, msalInstance }) => {
    const [inProgress, setInProgress] = useState(false)

    const safeLogin = async () => {
        if (inProgress) return
        try {
            setInProgress(true) // Set inProgress to true when starting an interaction
            await msalInstance.loginRedirect(loginRequest)
        } catch (error) {
            console.error("Authentication error:", error)
        } finally {
            setInProgress(false) // Set inProgress back to false when done
        }
    }

    const getAllAccounts = (): AccountInfo[] | undefined => {
        if (inProgress) return
        return msalInstance.getAllAccounts();
    }

    const acquireTokenSilent = async (tokenReq: { scopes: string[], account: AccountInfo }) => {
        if (inProgress) return
        setInProgress(true)
        return await msalInstance.acquireTokenSilent(tokenReq).then((response) => {
            setInProgress(false)
            return response
        }).catch((e) => {
            console.error(e)
            setInProgress(false)
        });
    }

    const acquireTokenPopup = async (tokenReq: { scopes: string[], account: AccountInfo }) => {
        if (inProgress) return
        setInProgress(true)
        return await msalInstance.acquireTokenPopup(tokenReq).then((response) => {
            setInProgress(false)
            return response
        }).catch((e) => {
            console.error(e)
            setInProgress(false)
        });
    }

    const acquireToken = async () => {
        const accounts = getAllAccounts()

        if (!accounts) return

        let token

        const accessTokenRequest = { scopes: [""], account: accounts[0] }

        token = await acquireTokenSilent(accessTokenRequest)

        if (!token) { token = await acquireTokenPopup(accessTokenRequest) }

        if (!token) return

        return token.idToken
    }

    return (
        <MsalWrapperContext.Provider value={{ inProgress, safeLogin, getAllAccounts,
            acquireTokenSilent, acquireTokenPopup, acquireToken }}>
            {children}
        </MsalWrapperContext.Provider>
    )
}

export const useMsalWrapper = (): MsalWrapperContextType => {
    const context = useContext(MsalWrapperContext)
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider')
    }
    return context
}
