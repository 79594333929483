import {Button, Input, Modal, TablePaginationConfig, Typography} from "antd";
import React from "react";
import {useTranslation} from "react-i18next";
import {User} from "../contexts/UserInfoContext";
import {ColumnsType} from "antd/es/table";
import {UserTable} from "../components/UserTable";

const {Text} = Typography;

interface iUserListModalProps {
    addModal: boolean,
    setAddModal: (status: boolean) => void,
    userList: User[],
    rowSelection: { onChange: (selectedRowKeys: any, selectedRows: User[]) => void; getCheckboxProps: (record: User) => { name: string; }; },
    columns: ColumnsType<User>,
    pagination?: TablePaginationConfig,
    searchUsers: (searchTerm: string) => void,
    searchedUsers: User[],
    disabled?: boolean,
    onAddUser?: () => void
}

export const UserListModal: React.FC<iUserListModalProps> = (props: iUserListModalProps) => {

    const {t} = useTranslation();

    return (
        <Modal mask={false} open={props.addModal} onCancel={() => props.setAddModal(false)} footer={null} width={750}>
            <div className="space-y-3">
                <Text>{t('Select users')}</Text>
                <Input
                    placeholder="Search user's name"
                    onInput={(e) => {
                        props.searchUsers(e.currentTarget.value)}}
                />
                <UserTable pagination={props.pagination} rowSelection={props.rowSelection} columns={props.columns} userList={props.searchedUsers} />
                <Button shape="round" size="small" style={{color: 'black'}} className="flex ml-auto" disabled={props.disabled} onClick={props.onAddUser}
                >
                    {t("Add user")}
                </Button>
            </div>

        </Modal>
    )
}
