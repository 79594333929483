import axios from "axios";
import {User} from "../contexts/UserInfoContext";
import PermissionType from "../models/PermissionType";

const getUser = async (idToken: string) => {
    const url = process.env.REACT_APP_USERS_BACKEND;
    if (!url) throw new Error("Missing User API");

    const headers = { Authorization: `Bearer ${idToken}` };
    try {
        const getResponse = await axios.get(`${url}/me`, { headers: headers });
        const user: User = getResponse.data
        const auth: any = await getAuth(idToken)
        const typedAuthArr: PermissionType[] = auth as PermissionType[]
        user.permissions = typedAuthArr
        return user;
    } catch (error: any) {
        console.error("Did not find User");
        throw error;
    }
};

const getAuth = async (idToken: string) => {
    const url = process.env.REACT_APP_AUTH_BACKEND
    if (!url) throw new Error("Missing Auth API")

    const headers = { Authorization : `Bearer ${idToken}`}
    try {
        const response = await axios.get(`${url}`, { headers: headers })
        return response.data
    } catch (error: any) {
        console.error("Auth API error: " + error)
        throw error
    }
}

export { getUser, getAuth }
