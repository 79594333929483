import {Card, Layout} from "antd";
import {Header} from "antd/es/layout/layout";
import {NavBar} from "../components/NavBar";
import {getUserList} from "../utils/api/AxiosUtils";
import {User} from "../contexts/UserInfoContext";
import React, {useEffect, useState} from "react";
import {TitleText} from "../components/TitleText";
import {useTranslation} from "react-i18next";
import {UIGuard} from "../utils/PermGuard";
import EFeature from "../enums/EFeature";
import EFeatureScope from "../enums/EFeatureScope";
import {useMsalWrapper} from "../contexts/MSALContext";

export const LandingPage = () => {

    const [userList, setUserList] = useState<User[]>([])

    const {t} = useTranslation();

    const { acquireToken } = useMsalWrapper()

    const fetchUsers = async () => {
        const token = await acquireToken()
        if (!token) return

        console.log(token)

        const response = await getUserList(token)

        if (!response) return

        const userData = response.data.users

        userData.forEach((user: User) => {
            user.key = user.token
        })
        setUserList(userData)
    }

    useEffect(() => {
        fetchUsers()
    }, [])

    return (
        <Layout className="frame">

                <Header id="search-header">
                    <NavBar />
                </Header>

                <div className="pageBody">
                    <UIGuard routePerms={[{feature: EFeature.Users, scope: EFeatureScope.Read}, {feature: EFeature.Roles, scope: EFeatureScope.Read}]}>
                        <TitleText text={t("Landing page")}/>
                        <Card className="mt-5" title={`Total users: ${userList.length}`}>

                        </Card>
                    </UIGuard>
                </div>
        </Layout>
    )
}
