import {Card, Layout, Popover, Table} from "antd";
import {Header} from "antd/es/layout/layout";
import {NavBar} from "../components/NavBar";
import {useTranslation} from "react-i18next";
import MessageType from "../models/MessageType";
import {ColumnsType} from "antd/es/table";
import React, {useEffect, useState} from "react";
import {getMessages} from "../utils/api/AxiosUtils";
import {TitleText} from "../components/TitleText";
import {useMsalWrapper} from "../contexts/MSALContext";

export const MessagesPage = () => {

    const [messageList, setMessageList] = useState<MessageType[]>([])

    const [page, setPage] = useState<number>(0)

    const [totalPages, setTotalPages] = useState<number>(0)

    const { acquireToken } = useMsalWrapper()

    const {t} = useTranslation();

    const columns: ColumnsType<MessageType> = [
        {
            title: 'Date of message sent',
            dataIndex: 'createdOn'
        },
        {
            title: 'Recipient',
            dataIndex: 'recipient'
        },
        {
            title: 'Subject',
            dataIndex: 'subject'
        },
        {
            title: 'Content',
            dataIndex: 'contents',
            render: (text, record) => (
                <Popover title={record.subject} content={<Content content={record.contents}/>} trigger="hover">
                    <span className="flex -mr-10" ><p className="truncate ... w-24">{record.contents}</p></span>
                </Popover>
            )
        }
    ]

    const fetchMessages = async () => {
        const token = await acquireToken()
        if (!token) return

        const response = await getMessages(token, page, 10)

        if (!response) return

        setTotalPages(response.data.pagination.total)

        setMessageList(response.data.messages)
    }

    useEffect(() => {
        fetchMessages()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    const Content = ({content} : {content: string}) => {
        return (<div className="w-fit">
            <p>{content}</p>
        </div>)
    }

    return (
        <Layout className="frame">
            <Header id="search-header">
                <NavBar />
            </Header>
            <div className="pageBody">
                <TitleText text={t("Messages")}/>
                <div className="flex flex-col">
                    <Card>
                        <div >
                            <Table
                                columns={columns}
                                pagination={{ defaultPageSize: 10, total: totalPages, onChange: (page) => setPage(page - 1) }}
                                dataSource={messageList}
                            />
                        </div>
                    </Card>
                </div>
            </div>
        </Layout>
    )
}
