import TransactionType from "../models/TransactionType";
import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {User} from "../contexts/UserInfoContext";
import {getUserByToken} from "../utils/api/AxiosUtils";
import {Header} from "antd/es/layout/layout";
import {NavBar} from "../components/NavBar";
import {Card, Collapse, Layout, Row, Space, Typography} from "antd";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {TitleText} from "../components/TitleText";
import {useTranslation} from "react-i18next";
import {DataRow} from "../components/DataRow";
import {format} from "date-fns";
import {useMsalWrapper} from "../contexts/MSALContext";

const { Link } = Typography;

interface iTransactionDetailPageProp {
    location: {
        state: {
            transaction: TransactionType
        }
    }
}

const TransactionDetailPage: React.FC<iTransactionDetailPageProp> = (props) => {

    const [currentUser, setCurrentUser] = useState<User>()

    const { acquireToken } = useMsalWrapper()

    const {t} = useTranslation();

    const navigate = useNavigate()

    useEffect(() => {
        const getUser = async () => {
            const token = await acquireToken()
            if (!token) return
            const response = await getUserByToken(token, props.location.state.transaction.user)

            if (!response) return
            setCurrentUser(response.data)
        }

        getUser()
    }, [props.location.state.transaction.user])

    return (<Layout className="frame">
        <Header id="search-header">
            <NavBar />
        </Header>
        <div className="pageBody">
            <Row id="search-backlink">
                <Link onClick={() => navigate(-1)} className="text-xl">
                    <Space>
                        <ArrowLeftOutlined />
                        {t("Back to roles")}
                    </Space>
                </Link>
            </Row>
            <TitleText text={t(`Transaction ${props.location.state.transaction.id}`)}/>
            <div className="flex flex-col">
                <Card className="mt-5">
                    <div className="mb-2">
                        <DataRow text={`User: `} data={currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : '--'}/>
                        <DataRow text={``} data={currentUser?.email ? `${currentUser.email}` : '--'}/>
                        <DataRow text={``} data={currentUser?.createdOn ? `Created on ${format(new Date(currentUser.createdOn), 'yyyy-MM-dd')}` : '--'}/>
                        <DataRow text={``} data={currentUser?.lastUpdatedOn ? `Last updated on ${format(new Date(currentUser.lastUpdatedOn), 'yyyy-MM-dd')}` : '--'}/>
                    </div>
                    <DataRow text={`Transaction date: `} data={format(new Date(props.location.state.transaction.accessedDate), 'yyyy-MM-dd')}/>
                    <div className="mb-1"/>
                    <DataRow text={`Path: `} data={props.location.state.transaction.path}/>
                    <div className="mb-1"/>
                    <DataRow text={`Response: `} data={props.location.state.transaction.response}/>
                    <Collapse
                        className="mt-5"
                        items={[{ key: '1', label: 'Payload', children: <p>{props.location.state.transaction.payload}</p> }]}
                    />
                </Card>
            </div>
        </div>
    </Layout>)
}

export const TransactionDetailPageWrapper: React.FC = () => {
    const location = useLocation()

    return <TransactionDetailPage location={location}/>
}

