import PermissionType from "../models/PermissionType";
import EFeature from "../enums/EFeature";
import EFeatureScope from "../enums/EFeatureScope";
import {useNavigate} from "react-router-dom";
import React, {useContext, useEffect} from "react";
import {UserContext} from "../contexts/UserInfoContext";

// Check if provided user permissions sufficient for route permission (if route perms exist)
const hasPerms = (userPerms: PermissionType[], routePerms?: {feature: EFeature, scope: EFeatureScope} | {feature: EFeature, scope: EFeatureScope}[] ) => {
    if (!routePerms) return true

    if (!Array.isArray(routePerms)) return userPerms.some(
        (permission) =>
            EFeature[permission.feature as keyof typeof EFeature] === routePerms.feature &&
            EFeatureScope[permission.scope as keyof typeof EFeatureScope] === routePerms.scope
    )

    return routePerms.some((routePerms) => userPerms.some(
        (permission) =>
            EFeature[permission.feature as keyof typeof EFeature] === routePerms.feature &&
            EFeatureScope[permission.scope as keyof typeof EFeatureScope] === routePerms.scope
    ))
}

// HOC RouteGuard wraps RouteObject[] elements
// Navigate to unauthorized page if permissions insufficient
const RouteGuard: React.FC<{ route: any, routePerms?: {feature: EFeature, scope: EFeatureScope}[] }> = ({ route, routePerms }) => {
    const user = useContext(UserContext)
    const navigate = useNavigate()

    let isAuthed: boolean

    if (user?.permissions) { isAuthed = hasPerms(user.permissions, routePerms) } else { isAuthed = false }


    useEffect(() => {
        const permRoute = () => {
            if (!user) return
            if (!user.permissions) navigate('/unauthorized')
            if (!isAuthed) navigate('/unauthorized')
        }

        permRoute()

    }, [navigate, isAuthed, user])

    if (!user?.permissions) return route

    return isAuthed ? route : null
}

const UIGuard: React.FC<{ routePerms?: {feature: EFeature, scope: EFeatureScope} | {feature: EFeature, scope: EFeatureScope}[], children: React.ReactNode }> = ({ routePerms, children }) => {
    const user = useContext(UserContext)

    const isAuthed = user?.permissions ? hasPerms(user.permissions, routePerms) : false

    return isAuthed ? <>{children}</> : null
}

export { RouteGuard, UIGuard, hasPerms }
