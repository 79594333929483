import {Row, Col, Typography, Layout} from "antd";
import { useTranslation } from "react-i18next";
import {Header} from "antd/es/layout/layout";
import {NavBar} from "../components/NavBar";

const { Text, Title } = Typography;

export const UnauthorizedPage = () => {
    const { t } = useTranslation();

    return (
        <Layout className="frame">
            <Header id="search-header">
                <NavBar />
            </Header>
            <Row className="justify-center items-center mt-20">
                <Col className="search-error">
                    <Row>
                        <img src={process.env.PUBLIC_URL + "/icons/sad.svg"} alt="" />
                    </Row>
                    <Row>
                        <Title level={3}>{t("Unauthorized")}</Title>
                    </Row>
                    <Row>
                        <Text>{t("The page you requested requires permissions you do not have")}</Text>
                    </Row>
                    <Row>
                        <Text>{t("Please return to the homepage and try again")}</Text>
                    </Row>
                </Col>
            </Row>
        </Layout>
    );
};
