import axios, {AxiosResponse} from "axios";
import axiosRetry from "axios-retry";
import PostRoleType from "../../models/PostRoleType";
import PostFeatureType from "../../models/PostFeatureType";
import {PostTemplateType} from "../../models/TemplateType";

const axiosInstance = axios

axiosRetry(axios, {retryDelay: axiosRetry.exponentialDelay})

// Abstracted get method, has optional pagination
const getReqPagination = async (token: string, url: string, pagination?: boolean, page?: number, size?: number) => {
    const parameter = pagination ? {
        page: page ? page : 0,
        size: size ? size : 10
    } : {}

    const response: void | AxiosResponse = await axiosInstance.get(url, {
        params: parameter,
        headers: {
            Authorization: 'Bearer ' + token
        }
    }).then((response) => response).catch(e => console.error(e))

    return response
}

// Get user list (User[])
const getUserList = async (token: string, page?: number, size?: number) => {

    const url = process.env.REACT_APP_USERS_BACKEND

    if (!url) return

    return await getReqPagination(token, url, true, page, size)
}

// Create new user w/ first & last name, & email
const addUser = async (token: string, firstName: string, lastName: string, email: string) => {

    const url = process.env.REACT_APP_USERS_BACKEND

    if (!url) return

    const data = {
        firstName: firstName,
        lastName: lastName,
        email: email
    }

    const response: void | AxiosResponse = await axiosInstance.post(`${url}/internal`, data, {headers: {Authorization: 'Bearer ' + token}}).then((response) => response).catch(e => console.error(e))
    return response
}

// Delete user via token
const deleteUser = async (token: string, userToken: string) => {
    const url = process.env.REACT_APP_USERS_BACKEND

    if (!url) return

    const response: void | AxiosResponse = await axiosInstance.delete(`${url}/${userToken}`, {headers: {Authorization: 'Bearer ' + token}}).then((response) => response).catch(e => console.error(e))
    return response
}

// Toggle user status
const toggleUser = async (token: string, userToken: string, disable?: boolean) => {
    const url = process.env.REACT_APP_USERS_BACKEND

    if (!url) return

    const enableStatus = disable ? 'disable' : 'enable'

    const response: void | AxiosResponse = await axiosInstance.post(`${url}/${userToken}/${enableStatus}`, {}, {headers: {Authorization: 'Bearer ' + token}}).then((response) => response).catch(e => console.error(e))
    return response
}

// Get roles (RolesType[])
const getRoles = async (token: string, page?: number, size?: number) => {
    const url = process.env.REACT_APP_ACCESS_BACKEND

    if (!url) return

    return await getReqPagination(token, `${url}/roles`, true, page, size)
}

// Get all available permissions (FeaturePermType[])
const getAllPerms = async (token: string) => {
    const url = process.env.REACT_APP_ACCESS_BACKEND

    if (!url) return

    return await getReqPagination(token, `${url}/permissions/all`, false)
}

// Post new role (PostRoleType)
const postNewRole = async (token: string, postRoll: PostRoleType) => {
    const url = process.env.REACT_APP_ACCESS_BACKEND

    if (!url) return

    return await axiosInstance.post(`${url}/roles`, postRoll,
        {headers: {Authorization: 'Bearer ' + token}}).then((response) => response).catch(e => console.error(e))
}

// Delete role via code
const deleteRole = async (token: string, roleCode: string) => {
    const url = process.env.REACT_APP_ACCESS_BACKEND

    if (!url) return

    return await axiosInstance.delete(`${url}/roles/${roleCode}`, {headers: {Authorization: 'Bearer ' + token}}).then((response) => response).catch(e => console.error(e))
}

// Put updated member list (user tokens string[]) to role
const assignRoleMembers = async (token: string, roleCode: string, tokenArr: string[]) => {
    const url = process.env.REACT_APP_ACCESS_BACKEND

    if (!url) return

    return await axiosInstance.put(`${url}/roles/${roleCode}/assign`, { members: tokenArr },
        {headers: {Authorization: 'Bearer ' + token}}).then((response) => response).catch(e => console.error(e))
}

// Put updated permissions to role
const updateRolePerms = async (token: string, roleCode: string, permList: PostFeatureType[]) => {
    const url = process.env.REACT_APP_ACCESS_BACKEND

    if (!url) return

    return await axiosInstance.put(`${url}/roles/${roleCode}/grant`, { permissions: permList },
        {headers: {Authorization: 'Bearer ' + token}}).then((response) => response).catch(e => console.error(e))
}

// Get user account via token (User)
const getUserByToken = async (token: string, userToken: string) => {
    const url = process.env.REACT_APP_USERS_BACKEND

    if (!url) return

    return await getReqPagination(token, `${url}/${userToken}`, false)
}

// Get messages (MessageType[])
const getMessages = async (token: string, page?: number, size?: number) => {
    const url = process.env.REACT_APP_MESSAGE_BACKEND

    if (!url) return

    return await getReqPagination(token, url, true, page, size)
}

// Get transactions (TransactionType[])
const getTransactions = async (token: string, page?: number, size?: number) => {
    const url = process.env.REACT_APP_LOGGING_BACKEND

    if (!url) return

    return await getReqPagination(token, `${url}/transactions`, true, page, size)
}

// Get templates (TemplateType[])
const getTemplates = async (token: string, page?: number, size?: number) => {
    const url = process.env.REACT_APP_TEMPLATE_BACKEND

    if (!url) return

    return await getReqPagination(token, url, true, page, size)
}

// Post new template (PostTemplateType)
const postNewTemplate = async (token: string, postTemplate: PostTemplateType) => {
    const url = process.env.REACT_APP_TEMPLATE_BACKEND

    if (!url) return

    return await axiosInstance.post(`${url}`, postTemplate,
        {headers: {Authorization: 'Bearer ' + token}}).then((response) => response).catch(e => console.error(e))
}


export { getUserList, addUser, deleteUser, toggleUser,
    getRoles, getAllPerms, postNewRole, deleteRole,
    getUserByToken, getMessages, assignRoleMembers, updateRolePerms,
    getTransactions, getTemplates, postNewTemplate}
