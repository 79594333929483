import { Row, Col, Typography, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import {useNavigate} from "react-router-dom";

const { Text } = Typography;

interface DataRowProps {
    text: string;
    data: any;
    tooltip?: string;
    navigation?: string
}

export const DataRow = ({ text, data, tooltip, navigation }: DataRowProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <Row className="data-row" wrap={false}>
            <Col span={14}>
                <Tooltip title={tooltip ?? text}>
                    <Text strong>{t(text)}</Text>
                </Tooltip>
            </Col>
            <Col>
                {navigation?
                    <button onClick={() => navigate({ pathname: navigation })}>{data}</button>
                    :
                    <Text type="secondary" >{data}</Text>
                }
            </Col>
        </Row>
    ) ;
};
