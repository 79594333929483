import {Button, Card, Input, Layout, Table} from "antd";
import {Header} from "antd/es/layout/layout";
import {NavBar} from "../components/NavBar";
import {deleteRole, getAllPerms, getRoles, postNewRole} from "../utils/api/AxiosUtils";
import React, {useEffect, useState} from "react";
import {TitleText} from "../components/TitleText";
import {useTranslation} from "react-i18next";
import {ColumnsType} from "antd/es/table";
import RolesType from "../models/RolesType";
import {AddRoleModal} from "../modals/AddRoleModal";
import FeaturePermType from "../models/FeaturePermType";
import PostRoleType from "../models/PostRoleType";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {useMsalWrapper} from "../contexts/MSALContext";

export const RolesPage = () => {

    const [roleList, setRoleList] = useState<RolesType[]>([])

    const [searchedRoles, setSearchedRoles] = useState<RolesType[]>([])

    const [permList, setPermList] = useState<FeaturePermType[]>([])

    const [addModal, setAddModal] = useState<boolean>(false)

    const { acquireToken } = useMsalWrapper()

    const navigate = useNavigate()

    const {t} = useTranslation();

    const columns: ColumnsType<RolesType> = [
        {
            title: 'Role Name',
            dataIndex: 'name',
        },
        {
            title: 'Description',
            dataIndex: 'description'
        },
        {
            title: 'Number of users',
            dataIndex: 'numberOfUsers',
            render: (text, record) => (
                <span className="flex justify-center">{record.members.length}</span>
            )
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            render: (text, record) => <div className="flex flex-row space-x-2">
                <button className="flex justify-center" onClick={() => navigate(`/role/${record.id}`, { state: { role : record, permList: permList } })}><img className="rounded" width={20}
                                                        src={process.env.PUBLIC_URL + "/icons/eye.svg"}
                                                        alt={'view-detailed'}/></button>
                <button className="flex justify-center" onClick={() => onClickDelete(record)}><img className="rounded" width={20}
                                                        src={process.env.PUBLIC_URL + "/icons/trash.svg"}
                                                        alt={'delete'}/></button>
            </div>,
        },
    ]

    const searchRoles = async (searchTerm: string) => {
        if (!searchTerm) {
            setSearchedRoles(roleList)
            return
        }

        const searchedList = searchedRoles.filter((role) => role.name.toLowerCase().includes(searchTerm.toLowerCase()))

        if (!searchedList) {
            return
        }

        setSearchedRoles(searchedList)
    }

    const fetchAllPerms = async () => {
        const token = await acquireToken()
        if (!token) return

        const response = await getAllPerms(token)

        if (!response) return

        setPermList(response.data)
    }

    const fetchRoles = async () => {
        const token = await acquireToken()
        if (!token) return

        const response = await getRoles(token)

        if (!response) return

        setRoleList(response.data.roles)

        setSearchedRoles(response.data.roles)
    }

    const onPostRole = async (postRoll: PostRoleType) => {
        const token = await acquireToken()
        if (!token) return

        const response = await postNewRole(token, postRoll)

        if (!response) {
            toast.error('Failed to post role', {position: "bottom-center", autoClose: 5000, closeOnClick: true})
            return
        }

        toast.success('Posted role successfully', {position: "bottom-center", autoClose: 5000, closeOnClick: true})
        fetchRoles()
    }

    const onClickDelete = (role: RolesType) => {
        removeRole(role).then(() => {
            fetchRoles()
        })
    }

    const removeRole = async (role: RolesType) => {
        const token = await acquireToken()
        if (!token) return

        const response = await deleteRole(token, role.code)

        if (!response) {
            toast.error(`Failed to delete role ${role.name}`, {position: "bottom-center", autoClose: 5000, closeOnClick: true})
            return
        }
    }

    const toggleAddModal = () => { setAddModal(!addModal) }

    useEffect(() => {
        fetchAllPerms()
        fetchRoles()}
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [])

    return (
        <Layout className="frame">
            <Header id="search-header">
                <NavBar />
            </Header>
            <div className="pageBody">
                <TitleText text={t("Roles management")}/>
                <div className="flex flex-col">
                    <div className="pt-1 pb-2 flex flex-row justify-center items-center space-x-3">
                        <Input
                            placeholder="Search roles"
                            onInput={(e) => {
                                searchRoles(e.currentTarget.value)}}
                        />
                        <Button shape="round" size="small" style={{color: 'black'}} onClick={toggleAddModal}
                        >
                            {t("Create role")}
                        </Button>
                    </div>
                    <Card>
                        <div>
                            <Table
                                columns={columns}
                                dataSource={searchedRoles}/>
                        </div>
                    </Card>
                </div>
            </div>
            <AddRoleModal onAddRole={onPostRole} permList={permList} addModal={addModal} setAddModal={toggleAddModal}/>
        </Layout>
    )
}
