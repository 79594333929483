import {Button, Card, Col, Layout, Row, Space, Typography} from "antd";
import {Header} from "antd/es/layout/layout";
import {NavBar} from "../components/NavBar";
import React, {useEffect, useState} from "react";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {TitleText} from "../components/TitleText";
import {useTranslation} from "react-i18next";
import RolesType from "../models/RolesType";
import {useLocation, useNavigate} from "react-router-dom";
import {User} from "../contexts/UserInfoContext";
import {assignRoleMembers, getUserList, updateRolePerms} from "../utils/api/AxiosUtils";
import {ColumnsType} from "antd/es/table";
import {UserTable} from "../components/UserTable";
import FeaturePermType from "../models/FeaturePermType";
import PostFeatureType from "../models/PostFeatureType";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import LabelType from "../models/LabelType";
import {PermissionSelectGroup} from "../components/PermissionSelectGroup";
import {UserListModal} from "../modals/UserListModal";
import {toast} from "react-toastify";
import PermissionType from "../models/PermissionType";
import {UIGuard} from "../utils/PermGuard";
import EFeature from "../enums/EFeature";
import EFeatureScope from "../enums/EFeatureScope";
import {fetchAllUsers} from "../utils/api/ConsumeUtils";
import {useMsalWrapper} from "../contexts/MSALContext";

const { Text } = Typography;
const { Link } = Typography;

interface iRoleDetailPageProp {
    location: {
        state: {
            role: RolesType,
            permList: FeaturePermType[]
        }
    }
}

const RoleDetailPage: React.FC<iRoleDetailPageProp> = (props) => {

    const [userList, setUserList] = useState<User[]>([])

    const [addUserList, setAddUserList] = useState<User[]>([])

    const [currentMembers, setCurrentMembers] = useState<string[]>(props.location.state.role.members)

    const [currentPerms, setCurrentPerms] = useState<PermissionType[]>(props.location.state.role.permissions)

    const [addModal, setAddModal] = useState<boolean>(false)

    const [selectedUsers, setSelectedUsers] = useState<User[]>([])

    const [searchedUsers, setSearchedUsers] = useState<User[]>([])

    const [selectedAddUsers, setAddSelectedUsers] = useState<User[]>([])

    const [selectedPermList, setSelectedPermList] = useState<PostFeatureType[]>([])

    const [currentTab, setCurrentTab] = useState<string>('members')

    const [page, setPage] = useState<number>(0)


    const [totalPages, setTotalPages] = useState<number>(0)

    const navigate = useNavigate()

    const { acquireToken } = useMsalWrapper()

    const {t} = useTranslation();

    const columns: ColumnsType<User> = [
        {
            title: 'Name',
            dataIndex: 'fullName',
            render: (text, record) => (
                <span>{record.firstName} {record.lastName}</span>
            )
        },
        {
            title: 'Email',
            dataIndex: 'email'
        },
    ]

    const addColumns: ColumnsType<User> = [
        {
            title: 'Name',
            dataIndex: 'fullName',
            render: (text, record) => (
                <span>{record.firstName} {record.lastName}</span>
            )
        },
        {
            title: 'Email',
            dataIndex: 'email'
        },
    ]

    const onCheckPerm = (e: CheckboxChangeEvent, scope: { key: string; label: LabelType; }, permName: string) => {

        if (!e.target.checked) {
            setSelectedPermList(selectedPermList.filter(item => !(item.feature === permName && item.scope === scope.key)))
            return
        }
        const feature: PostFeatureType = {
            feature: permName,
            scope: scope.key,
            exclusions: [""]
        }

        setSelectedPermList(oldArr => [...oldArr, feature])
    }

    useEffect(() => {
        const fetchUsers = async () => {
            const token = await acquireToken()
            if (!token) return

            if (currentMembers.length < 0) return

            const userArr = await fetchAllUsers(token, props.location.state.role.members)

            if (!userArr) return

            userArr.forEach((user: User) => {
                user.key = user.token
            })

            setUserList(userArr)
        }
        fetchUsers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMembers])



    const rowAddSelection = {
        onChange: (selectedRowKeys: any, selectedRows: User[]) => {
            setAddSelectedUsers(selectedRows)
        },
        getCheckboxProps: (record: User) => ({
            name: record.firstName,
        }),
    };

    const toggleAddModal = () => { setAddModal(!addModal) }

    const fetchModalUsers = async () => {
        const token = await acquireToken()
        if (!token) return

        const response = await getUserList(token, page, 10)

        if (!response) return

        setTotalPages(response.data.pagination.total)

        const userData = response.data.users

        userData.forEach((user: User) => {
            user.key = user.token
        })
        setAddUserList(userData)
        // setSearchedUsers(userData)
        setSearchedUsersFilter(userData)
    }

    useEffect(() => {
        fetchModalUsers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    const rowSelection = {
        onChange: (selectedRowKeys: any, selectedRows: User[]) => {
            setSelectedUsers(selectedRows)
        },
        getCheckboxProps: (record: User) => ({
            name: record.firstName,
        }),
    };

    const setSearchedUsersFilter = (userData: User[]) => {
        const searchedList = userData.filter((user) => !props.location.state.role.members.includes(user.token))

        setSearchedUsers(searchedList)
    }

    const searchUsers = async (searchTerm: string) => {
        if (!searchTerm) {
            setSearchedUsersFilter(addUserList)
            return
        }

        const searchedList = searchedUsers.filter((user) => (user.firstName.toLowerCase() + user.lastName.toLowerCase()).includes(searchTerm.toLowerCase()))

        if (!searchedList) { return }

        setSearchedUsersFilter(searchedList)
    }

    const onRemoveUsers = async (userIds?: string[]) => {
        const token = await acquireToken()
        if (!token) return

        let ids = userIds && userIds

        if (!userIds) {
            ids = selectedUsers.map((user) => user.token)
        }

        const userIdSet: Set<string> = new Set(ids)

        const newUserIds = currentMembers.filter((memberId) => !userIdSet.has(memberId))

        const response = await assignRoleMembers(token, props.location.state.role.code, newUserIds)

        if (!response) {
            toast.error('Failed to add user', {position: "bottom-center", autoClose: 5000, closeOnClick: true})
            return
        }

        props.location.state.role.members = newUserIds

        setCurrentMembers(newUserIds)

        await fetchModalUsers()
    }

    const onAddUser = async () => {
        const token = await acquireToken()
        if (!token) return

        if (selectedAddUsers.length < 0) return
        let memberTokens: string[] = selectedAddUsers.map((user) => user.token).concat(currentMembers)

        const response = await assignRoleMembers(token, props.location.state.role.code, memberTokens)

        if (!response) {
            toast.error('Failed to add user', {position: "bottom-center", autoClose: 5000, closeOnClick: true})
            return
        }

        props.location.state.role.members = memberTokens

        setCurrentMembers(memberTokens)

        await fetchModalUsers()
        setAddModal(false)
    }

    useEffect(() => {
        const rolePerms = props.location.state.role.permissions.map((perm) => {
            return {
                feature: perm.feature,
                scope: perm.scope,
                exclusions: [""]
            }
        })
        setSelectedPermList(rolePerms)
    }, [props.location.state.role.permissions])

    const onUpdatePerms = async () => {
        const token = await acquireToken()
        if (!token) return

        const response = await updateRolePerms(token, props.location.state.role.code, selectedPermList)

        if (!response) {
            toast.error('Failed to update permissions', {position: "bottom-center", autoClose: 5000, closeOnClick: true})
            return
        }

        toast.success('Successfully updated permissions', {position: "bottom-center", autoClose: 5000, closeOnClick: true})

        const updatedRolePerms: PermissionType[] = selectedPermList.map((perm) => {
            return { feature: perm.feature, scope: perm.scope }
        } )
        //
        // console.log(updatedRolePerms)

        setCurrentPerms(updatedRolePerms)
        props.location.state.role.permissions = updatedRolePerms
    }

    return (
        <Layout className="frame">
            <Header id="search-header">
                <NavBar />
            </Header>
            <div className="pageBody">
                <Row id="search-backlink">
                    <Link onClick={() => navigate(-1)} className="text-xl">
                        <Space>
                            <ArrowLeftOutlined />
                            {t("Back to roles")}
                        </Space>
                    </Link>
                </Row>
                <TitleText text={t(`${props.location.state.role.name}`)}/>
                <div className="flex flex-row w-full space-x-4 mt-2 font-bold">
                    <UIGuard routePerms={{feature: EFeature.Roles, scope: EFeatureScope.Assign}}>
                        <Col
                            className={"category " + (currentTab === 'members' ? "active" : '')}
                            onClick={() => setCurrentTab('members')}
                        >
                            <Text  className="category-title clickable" >
                                Members
                            </Text>
                        </Col>
                    </UIGuard>
                    <UIGuard routePerms={{feature: EFeature.Roles, scope: EFeatureScope.Grant}}>
                        <Col
                            className={"category " + (currentTab === 'permissions' ? "active" : '')}
                            onClick={() => setCurrentTab('permissions')}
                        >
                            <Text  className="category-title clickable" >
                                Permissions
                            </Text>
                        </Col>
                    </UIGuard>
                </div>
                <div className="flex flex-col">
                    {currentTab === 'members' ?
                        <div className="flex flex-col">
                            <UIGuard routePerms={{feature: EFeature.Roles, scope: EFeatureScope.Assign}}>
                                <Row className="card-buttons mb-3" wrap={false}>
                                    <Button shape="round" size="small" style={{color: 'black'}} onClick={toggleAddModal}
                                    >
                                        {t("Add user")}
                                    </Button>
                                    <Button shape="round" size="small" style={{backgroundColor: 'var(--red)', color: 'white'}} disabled={selectedUsers.length === 0} onClick={() => onRemoveUsers()}
                                    >
                                        {t("Remove user")}
                                    </Button>
                                </Row>
                                <UserTable columns={columns} rowSelection={rowSelection} userList={userList}/>
                            </UIGuard>
                        </div>
                        :
                        <div className="flex flex-col space-y-4">
                            <UIGuard routePerms={{feature: EFeature.Roles, scope: EFeatureScope.Grant}}>
                                <Card className="mt-5">
                                    <PermissionSelectGroup onCheckPerm={onCheckPerm} permList={props.location.state.permList} rolePermList={currentPerms}/>
                                </Card>
                                <div className="flex ml-auto">
                                    <Button shape="round" size="small" style={{color: 'black'}} onClick={onUpdatePerms}
                                    >
                                        {t("Update permissions")}
                                    </Button>
                                </div>
                            </UIGuard>
                        </div>
                    }
                </div>
            </div>
            <UserListModal onAddUser={onAddUser} disabled={selectedAddUsers.length === 0} searchedUsers={searchedUsers} searchUsers={searchUsers} pagination={{ defaultPageSize: 10, total: totalPages, onChange: (page) => setPage(page - 1) }} columns={addColumns} rowSelection={rowAddSelection} userList={addUserList} addModal={addModal} setAddModal={setAddModal} />
        </Layout>
    )
}

export const RoleDetailPageWrapper: React.FC = () => {
    const location = useLocation()

    return <RoleDetailPage location={location}/>
}
