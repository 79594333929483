import { createContext } from "react";
import PermissionType from "../models/PermissionType";

export type Preference = {
    parameter: string;
    value: string;
};

type WishListItem = {
    type: "factory" | "supplier" | "product";
    reference: string;
};

export interface User {
    key?: string;
    token: string;
    firstName: string;
    lastName: string;
    email: string;
    createdOn: string;
    enabled: boolean;
    lastUpdatedOn: string;
    preferences: Preference[];
    wishListItems: WishListItem[];
    permissions: PermissionType[];
}

export const UserContext = createContext<User | null>(null);
