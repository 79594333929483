import {ErrorPage} from "./ErrorPage";
import {MaintenancePage} from "./MaintenancePage";
import {RootPage} from "./RootPage";
import {BackendErrorPage} from "./BackendErrorPage";
import React from "react";
import {LoginPage} from "./LoginPage";
import {LandingPage} from "./LandingPage";
import {UsersPage} from "./UsersPage";
import {RolesPage} from "./RolesPage";
import {TransactionsPage} from "./TransactionsPage";
import {MessagesPage} from "./MessagesPage";
import {UnauthorizedPage} from "./UnauthorizedPage";
import EFeature from "../enums/EFeature";
import EFeatureScope from "../enums/EFeatureScope";
import {RouteGuard} from "../utils/PermGuard";
import {RouteObject} from "react-router-dom";
import {RoleDetailPageWrapper} from "./RoleDetailPage";
import {TransactionDetailPageWrapper} from "./TransactionDetailPage";
import {MessengerPage} from "./MessengerPage";
import {TemplatePage} from "./TemplatePage";
import {TemplateDetailPageWrapper} from "./TemplateDetailPage";

// Unmapped & unguarded routerConfig RouteObject[]
const routerConfig = [
    {
        errorElement: <ErrorPage/>,
        children: [
            {
                element: <RootPage/>,
                children: [
                    {
                        path: "/",
                        element: <RouteGuard route={<LandingPage/>}/>,
                        permissions: [{feature: EFeature.Users, scope: EFeatureScope.Read}, {feature: EFeature.Roles, scope: EFeatureScope.Read}]
                    },
                    {
                        path: "/maintenance",
                        element: <RouteGuard route={<MaintenancePage/>}/>,
                    },
                    {
                        path: "/backend-error",
                        element: <BackendErrorPage/>,
                    },
                    {
                        path: "/unauthorized",
                        element: <UnauthorizedPage/>,
                    },
                    {
                        path: "/login",
                        element: <LoginPage/>,
                    },
                    {
                        path: "/users",
                        element: <UsersPage/>,
                        permissions: [{feature: EFeature.Users, scope: EFeatureScope.Read}]
                    },
                    {
                        path: "/roles",
                        element: <RolesPage/>,
                        permissions: [{feature: EFeature.Roles, scope: EFeatureScope.Read}]
                    },
                    {
                        path: "/transactions",
                        element: <TransactionsPage/>,
                        // permissions: {feature: EFeature.Transactions, scope: EFeatureScope.Read}
                    },
                    {
                        path: "/transactions/:id",
                        element: <TransactionDetailPageWrapper/>
                    },
                    {
                        path: "/messages",
                        element: <MessagesPage/>,
                        permissions: [{feature: EFeature.Messages, scope: EFeatureScope.Read}]
                    },
                    {
                        path: "/role/:id",
                        element: <RoleDetailPageWrapper/>,
                        permissions: [{feature: EFeature.Roles, scope: EFeatureScope.Read}]
                    },
                    {
                        path: "/messenger",
                        element: <MessengerPage/>,
                        permissions: [{feature: EFeature.Messenger, scope: EFeatureScope.Read}]
                    },
                    {
                        path: "/template",
                        element: <TemplatePage/>,
                        permissions: [{feature: EFeature.Messages, scope: EFeatureScope.Read}]
                    },
                    {
                        path: "/template/:id",
                        element: <TemplateDetailPageWrapper/>,
                        permissions: [{feature: EFeature.Messages, scope: EFeatureScope.Read}]
                    }
                ]
            }
        ]
    }
]

// Map RootPage children's element field w/ HOC RouteGuard
const routerMapped: RouteObject[] = routerConfig[0].children[0].children.map((route: {
    path: string,
    element: any,
    permissions?: { feature: EFeature, scope: EFeatureScope }[]
}) => {
    route.element = <RouteGuard route={route.element} routePerms={route.permissions} />
    return route
})

export {routerMapped, routerConfig}
