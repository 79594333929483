import {Button, Card, Layout, Table} from "antd";
import {Header} from "antd/es/layout/layout";
import {NavBar} from "../components/NavBar";
import React, {useEffect, useState} from "react";
import {TitleText} from "../components/TitleText";
import {useTranslation} from "react-i18next";
import {getTemplates} from "../utils/api/AxiosUtils";
import {TemplateType} from "../models/TemplateType";
import {ColumnsType} from "antd/es/table";
import {format} from "date-fns";
import {useNavigate} from "react-router-dom";
import EFeature from "../enums/EFeature";
import EFeatureScope from "../enums/EFeatureScope";
import {UIGuard} from "../utils/PermGuard";
import {useMsalWrapper} from "../contexts/MSALContext";

export const TemplatePage = () => {

    const [templateList, setTemplateList] = useState<TemplateType[]>([])

    const [page, setPage] = useState<number>(0)

    const [totalPages, setTotalPages] = useState<number>(0)

    const { acquireToken } = useMsalWrapper()

    const navigate = useNavigate()

    const {t} = useTranslation();

    const columns: ColumnsType<TemplateType> = [
        {
            title: 'Template ID',
            dataIndex: 'id',
            render: (text, record) => {
                return <div className="w-[150px] truncate ...">{record.id}</div>
            }
        },
        {
            title: 'Subject',
            dataIndex: 'subject'
        },
        {
            title: 'Created on',
            dataIndex: 'createdOn',
            render: (text, record) => {
                const date: Date = new Date(record.createdOn)

                const formattedDate = format(date, 'yyyy-MM-dd HH:mm:ss')
                return <span>{formattedDate}</span>
            }
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            render: (text, record) => <div className="flex justify-center flex-row space-x-2">
                <button className="flex justify-center" onClick={() => navigate(`/template/${record.id}`, { state: { template: record }})}><img className="rounded" width={20}
                                                             src={process.env.PUBLIC_URL + "/icons/eye.svg"}
                                                             alt={'view-detailed'}/></button>
                {/*<UIGuard routePerms={{feature: EFeature.Roles, scope: EFeatureScope.Delete}}>*/}
                {/*    <button className="flex justify-center"><img className="rounded" width={20}*/}
                {/*                                                 src={process.env.PUBLIC_URL + "/icons/copy.svg"}*/}
                {/*                                                 alt={'copy'}/></button>*/}
                {/*</UIGuard>*/}
            </div>,
        },
    ]

    useEffect(() => {
        const fetchTemplates = async () => {
            const token = await acquireToken()
            if (!token) return

            const response = await getTemplates(token, page, 10)

            if (!response) return

            setTotalPages(response.data.pagination.total)

            setTemplateList(response.data.templates)

            console.log(response)
        }
        fetchTemplates()
    }, [page])

    return (
        <Layout className="frame">
            <Header id="search-header">
                <NavBar/>
            </Header>
            <div className="pageBody">
                <TitleText text={t("Template page")}/>
                <div className="flex flex-col">
                    <div className="pt-1 pb-3 flex flex-row justify-center items-center space-x-3">
                        <UIGuard routePerms={{feature: EFeature.Roles, scope: EFeatureScope.Create}}>
                            <Button className="ml-auto" shape="round" size="small" style={{color: 'black'}} onClick={() => navigate(`/template/new`)}>
                                {t("Create Template")}
                            </Button>
                        </UIGuard>
                    </div>

                    <Card>
                        <div>
                            <Table columns={columns}
                                   dataSource={templateList}
                                   pagination={{
                                       defaultPageSize: 10,
                                       simple: true,
                                       total: totalPages,
                                       onChange: (page) => setPage(page - 1)
                                   }}/>
                        </div>
                    </Card>
                </div>
            </div>
        </Layout>
    )
}
