import {Button, Input, Modal, Typography} from "antd";
import RolesType from "../models/RolesType";
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {PermissionSelectGroup} from "../components/PermissionSelectGroup";
import FeaturePermType from "../models/FeaturePermType";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import LabelType from "../models/LabelType";
import PostFeatureType from "../models/PostFeatureType";
import PostRoleType from "../models/PostRoleType";

const {Text} = Typography

interface iAddRoleModalProps {
    addModal: boolean,
    setAddModal: (status: boolean) => void,
    setRole?: (role: RolesType) => void,
    permList: FeaturePermType[],
    onAddRole: (postRole: PostRoleType) => Promise<void>
}

export const AddRoleModal: React.FC<iAddRoleModalProps> = (props: iAddRoleModalProps) => {

    const [roleName, setRoleName] = useState<string>('')

    const [roleCode, setRoleCode] = useState<string>('')

    const [roleDesc, setRoleDesc] = useState<string>('')

    const [selectedPermList, setSelectedPermList] = useState<PostFeatureType[]>([])

    const [nameValid, setNameValid] = useState<boolean>(true)

    const [descValid, setDescValid] = useState<boolean>(true)

    const [codeValid, setCodeValid] = useState<boolean>(true)

    const [permValid, setPermValid] = useState<boolean>(true)

    const {t} = useTranslation();

    const onCheckPerm = (e: CheckboxChangeEvent, scope: { key: string; label: LabelType; }, permName: string) => {
        if (!e.target.checked) {
            setSelectedPermList(selectedPermList.filter(item => !(item.feature === permName && item.scope === scope.key)))
            return
        }
        const feature: PostFeatureType = {
            feature: permName,
            scope: scope.key,
            exclusions: [""]
        }

        setSelectedPermList(oldArr => [...oldArr, feature])
    }

    const validateInput = () => {
        if (!roleName) {
            setNameValid(false)
            return
        } else { setNameValid(true) }

        if (!roleCode) {
            setCodeValid(false)
            return
        } else { setCodeValid(true) }

        if (!roleDesc) {
            setDescValid(false)
            return
        } else { setDescValid(true) }

        if (selectedPermList.length === 0) {
            setPermValid(false)
            return
        } else { setPermValid(true) }

        return true
    }

    const onAddRole = async () => {

        const validated = validateInput()

        if (!validated) return

        const roleBody: PostRoleType = {
            name: roleName,
            code: roleCode,
            description: roleDesc,
            members: [],
            permissions: selectedPermList
        }

        setNameValid(true)
        setCodeValid(true)
        setDescValid(true)
        setPermValid(true)

        await props.onAddRole(roleBody)
    }

    return (
        <Modal mask={false} open={props.addModal} onCancel={() => props.setAddModal(false)} footer={null} width={750}>
            <div className="space-y-3">
                <Text>{t('Create a new role')}</Text>
                <div className="flex flex-row items-center space-x-3">
                    <Input
                        addonBefore="Name"
                        value={roleName}
                        onInput={(e) => setRoleName(e.currentTarget.value)}
                        maxLength={30}
                    />
                    <Input
                        addonBefore="Code"
                        value={roleCode}
                        onInput={(e) => setRoleCode(e.currentTarget.value)}
                        maxLength={30}
                    />
                </div>
                <Input
                    placeholder="Enter the role's description"
                    value={roleDesc}
                    onInput={(e) => setRoleDesc(e.currentTarget.value)}
                    maxLength={100}
                />
                <PermissionSelectGroup onCheckPerm={onCheckPerm} permList={props.permList}/>
                <Button shape="round" size="small" style={{color: 'black'}} className="flex ml-auto" onClick={() => onAddRole()}
                >
                    {t("Add role")}
                </Button>
                {!nameValid &&
                    <div>
                        <Text
                            className="text-[var(--red)] text-sm">{t('Name is missing, please enter role name')}</Text>
                    </div>}
                {!codeValid &&
                    <div>
                        <Text
                            className="text-[var(--red)] text-sm">{t('Code is missing, please enter role code')}</Text>
                    </div>}
                {!descValid &&
                    <div>
                        <Text
                            className="text-[var(--red)] text-sm">{t('Description is missing, please provide a description')}</Text>
                    </div>}
                {!permValid &&
                    <div>
                        <Text
                            className="text-[var(--red)] text-sm">{t('No permissions selected, minimum one permission is required')}</Text>
                    </div>}
            </div>
        </Modal>
    )
}
