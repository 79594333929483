import {Card, Table, TablePaginationConfig} from "antd";
import React from "react";
import {ColumnsType} from "antd/es/table";
import {User} from "../contexts/UserInfoContext";


interface iUserTableProps {
    rowSelection: { onChange: (selectedRowKeys: any, selectedRows: User[]) => void; getCheckboxProps: (record: User) => { name: string; }; },
    columns: ColumnsType<User>,
    userList: User[],
    pagination?: TablePaginationConfig
}

export const UserTable: React.FC<iUserTableProps> = (props) => {

    const paginationProps = props.pagination ? props.pagination : undefined

    return (
        <Card className="">
            <div>
                <Table
                    pagination={paginationProps}
                    // pagination={{props.pagination ? props.pagination : false}}
                    rowSelection={{
                        type: 'checkbox',
                        ...props.rowSelection,
                    }}
                    columns={props.columns}
                    dataSource={props.userList}
                />
            </div>
        </Card>
    )
}