import React, {useEffect} from 'react';
import './App.css';
import {PublicClientApplication} from "@azure/msal-browser";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "@rollbar/react";
import {MsalProvider} from "@azure/msal-react";

import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { hotjar } from "react-hotjar";

import {ConfigProvider} from "antd";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {routerConfig, routerMapped} from "./routes/routerConfig";
import {MsalWrapperProvider} from "./contexts/MSALContext";
const antdTheme = {
    token: {
        fontFamily:
            "Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji",
        colorPrimary: "#972d47",
        colorLink: "#972d47",
        colorLinkHover: "#e3436a",
    },
};


export const App = ({msalInstance}: { msalInstance: PublicClientApplication }) => {

    let routerObj = routerConfig
    // @ts-ignore
    routerObj[0].children[0].children = routerMapped

    const router = createBrowserRouter(routerObj)
    const helmetContext = {};

    const rollbarConfig = {
        accessToken: process.env.REACT_APP_ROLLBAR_POST_CLIENT_ITEM_TOKEN,
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
            client: {
                javascript: {
                    code_version: "1.0.0",
                    source_map_enabled: true,
                },
            },
        },
    };

    useEffect(() => {
        if (!process.env.REACT_APP_HJID || !process.env.REACT_APP_HJSV) {
            console.error("Unable to initialize hotjar, missing env vars");
            return
        }
        const hjid = parseInt(process.env.REACT_APP_HJID);
        const hjsv = parseInt(process.env.REACT_APP_HJSV);
        hotjar.initialize(hjid, hjsv);
    })

    return (
        <HelmetProvider context={helmetContext}>
            <Provider config={rollbarConfig}>
                <MsalProvider instance={msalInstance}>
                    <MsalWrapperProvider msalInstance={msalInstance}>
                        <ConfigProvider theme={antdTheme}>
                            <RouterProvider router={router} />
                            <ToastContainer />
                        </ConfigProvider>
                    </MsalWrapperProvider>
                </MsalProvider>
            </Provider>
        </HelmetProvider>
    )
}
