import {Card, Layout, Table} from "antd";
import {Header} from "antd/es/layout/layout";
import {NavBar} from "../components/NavBar";
import {TitleText} from "../components/TitleText";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ColumnsType} from "antd/es/table";
import TransactionType from "../models/TransactionType";
import {getTransactions} from "../utils/api/AxiosUtils";
import {format} from 'date-fns'
import {useNavigate} from "react-router-dom";
import {useMsalWrapper} from "../contexts/MSALContext";


export const TransactionsPage = () => {

    const [transactionList, setTransactionList] = useState<TransactionType[]>([])

    const [page, setPage] = useState<number>(0)

    const [totalPages, setTotalPages] = useState<number>(0)

    const { acquireToken } = useMsalWrapper()

    const navigate = useNavigate()

    const {t} = useTranslation();

    const columns: ColumnsType<TransactionType> = [
        {
            title: 'Date',
            dataIndex: 'accessedDate',
            render: (text, record) => {
                const date: Date = new Date(record.accessedDate)

                const formattedDate = format(date, 'yyy-MM-dd HH:mm:ss')
                return <span>{formattedDate}</span>
            }
        },
        {
            title: 'Request',
            dataIndex: 'path',
            render: (text, record) => (
                <span>{record.method} : {record.path}</span>
            )
        },
        {
            title: 'Response',
            dataIndex: 'response'
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            width: 12,
            render: (_, record) => <div className="flex justify-center">
                <button className="flex justify-center" onClick={() => navigate(`${record.id}`, { state: { transaction: record }})}><img className="rounded" width={20}
                                                             src={process.env.PUBLIC_URL + "/icons/eye.svg"}
                                                             alt={'view-detailed'}/></button>
            </div>,
        },
    ]



    useEffect(() => {
        const fetchTransactionList = async () => {
            const token = await acquireToken()
            if (!token) return

            const response = await getTransactions(token, page, 10)

            if (!response) return

            setTotalPages(response.data.pagination.total)

            setTransactionList(response.data.transactions)
        }

        fetchTransactionList()
    }, [page])

    return (
        <Layout className="frame">
            <Header id="search-header">
                <NavBar/>
            </Header>

            <div className="pageBody">
                <TitleText text={t("Transactions")}/>
                <div className="flex flex-col">
                    <Card>
                        <div>
                            <Table
                                columns={columns}
                                dataSource={transactionList}
                                pagination={{
                                    defaultPageSize: 10,
                                    simple: true,
                                    total: totalPages,
                                    onChange: (page) => setPage(page - 1)
                                }}/>
                        </div>
                    </Card>
                </div>
            </div>
        </Layout>
    )
}
