import {Layout} from "antd";
import {Header} from "antd/es/layout/layout";
import {NavBar} from "../components/NavBar";
import React from "react";
import {TitleText} from "../components/TitleText";
import {useTranslation} from "react-i18next";

export const MessengerPage = () => {

    const {t} = useTranslation();

    return (
        <Layout className="frame">
            <Header id="search-header">
                <NavBar />
            </Header>

            <div className="pageBody">
                <TitleText text={t("Messenger page")}/>
            </div>
        </Layout>
    )
}