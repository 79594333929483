import {Button, Input, Modal, Typography} from "antd";
import React from "react";
import {useTranslation} from "react-i18next";

const {Text} = Typography;

interface iAddUserModalProps {
    addModal: boolean,
    setAddModal: (status: boolean) => void,
    firstName: string | undefined,
    setFirstName: (firstName: string) => void,
    lastName: string | undefined,
    setLastName: (lastName: string) => void,
    email: string,
    setEmail: (email: string) => void,
    emailValid: boolean,
    nameValid: boolean,
    addUser: () => void
}

export const AddUserModal: React.FC<iAddUserModalProps> = (props: iAddUserModalProps) => {

    const {t} = useTranslation();

    return (
        <Modal mask={false} open={props.addModal} onCancel={() => props.setAddModal(false)} footer={null} width={750}>
            <div className="space-y-3">
                <Text>{t('Invite a new user')}</Text>
                <div className="flex flex-row items-center space-x-3">
                    <Input
                        addonBefore="First Name"
                        value={props.firstName}
                        onInput={(e) => {
                            props.setFirstName(e.currentTarget.value)
                        }}
                    />
                    <Input
                        addonBefore="Last Name"
                        value={props.lastName}
                        onInput={(e) => {
                            props.setLastName(e.currentTarget.value)
                        }}
                    />
                </div>
                <div className="flex flex-row items-center space-x-3">
                    <Input
                        addonBefore="Email"
                        value={props.email}
                        onInput={(e) => {
                            props.setEmail(e.currentTarget.value)
                        }}
                    />
                    <Button shape="round" size="small" style={{color: 'black'}} onClick={props.addUser}
                    >
                        {t("Add user")}
                    </Button>
                </div>
                {!props.emailValid && <div>
                    <Text
                        className="text-[var(--red)] text-sm">{t('Email is invalid, must contain an approved domain')}</Text>
                </div>}
                {!props.nameValid && <div>
                    <Text
                        className="text-[var(--red)] text-sm">{t('Name is missing, must contain a first and last name')}</Text>
                </div>}
            </div>

        </Modal>
    )
}