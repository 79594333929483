import {Badge, Col, Drawer, Row, Typography} from "antd";
import {useNavigate} from "react-router-dom";
import {UserDropdown} from "./UserDropDown";
import {useState} from "react";
import {UIGuard} from "../utils/PermGuard";
import EFeature from "../enums/EFeature";
import EFeatureScope from "../enums/EFeatureScope";


const { Link } = Typography;
export const NavBar = () => {
    const navigate = useNavigate()

    const [open, setOpen] = useState<boolean>(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <Row id="navbar">
            <Col className="lf-logo" onClick={() => navigate("/")}>
                <img
                    src={process.env.PUBLIC_URL + "/icons/LFLogo.svg"}
                    alt={"Li & Fung"}
                />
            </Col>
            <div className="hidden lg:flex space-x-10 ml-auto">
                <UIGuard routePerms={{feature: EFeature.Users, scope: EFeatureScope.Read}}>
                    <Badge>
                        <Link strong onClick={() => navigate("/users")}>User</Link>
                    </Badge>
                </UIGuard>
                <UIGuard routePerms={{feature: EFeature.Roles, scope: EFeatureScope.Read}}>
                    <Badge>
                        <Link strong onClick={() => navigate("/roles")}>Roles</Link>
                    </Badge>
                </UIGuard>
                <UIGuard routePerms={{feature: EFeature.Transactions, scope: EFeatureScope.Read}}>
                    <Badge>
                        <Link strong onClick={() => navigate("/transactions")}>Transactions</Link>
                    </Badge>
                </UIGuard>
                <UIGuard routePerms={{feature: EFeature.Messages, scope: EFeatureScope.Read}}>
                    <Badge>
                        <Link strong onClick={() => navigate("/messages")}>Messages</Link>
                    </Badge>
                </UIGuard>
                <UIGuard routePerms={{feature: EFeature.Messenger, scope: EFeatureScope.Read}}>
                    <Badge>
                        <Link strong onClick={() => navigate("/messenger")}>Messenger</Link>
                    </Badge>
                </UIGuard>
                <UIGuard routePerms={{feature: EFeature.Template, scope: EFeatureScope.Read}}>
                    <Badge>
                        <Link strong onClick={() => navigate("/template")}>Template</Link>
                    </Badge>
                </UIGuard>
                <UserDropdown />
            </div>

            <div
                onClick={showDrawer}
                className="HAMBURGER-ICON lg:hidden space-y-2 cursor-pointer"
            >
                <span className="block h-[3px] rounded-full w-8 bg-[var(--red)]"></span>
                <span className="block h-[3px] rounded-full w-8 bg-[var(--red)]"></span>
                <span className="block h-[3px] rounded-full w-8 bg-[var(--red)]"></span>
            </div>
            <Drawer placement="right" onClose={onClose} open={open}>
                <div className="flex flex-col space-y-8">
                    <UserDropdown />
                    <UIGuard routePerms={{feature: EFeature.Users, scope: EFeatureScope.Read}}>
                        <Badge>
                            <Link className="text-xl" strong onClick={() => navigate("/users")}>User</Link>
                        </Badge>
                    </UIGuard>
                    <UIGuard routePerms={{feature: EFeature.Roles, scope: EFeatureScope.Read}}>
                        <Badge>
                            <Link className="text-xl" strong onClick={() => navigate("/roles")}>Roles</Link>
                        </Badge>
                    </UIGuard>
                    <UIGuard
                        // routePerms={{feature: EFeature.Transactions, scope: EFeatureScope.Read}}
                    >
                        <Badge>
                            <Link className="text-xl" strong onClick={() => navigate("/transactions")}>Transactions</Link>
                        </Badge>
                    </UIGuard>
                    <UIGuard routePerms={{feature: EFeature.Messages, scope: EFeatureScope.Read}}>
                        <Badge>
                            <Link className="text-xl" strong onClick={() => navigate("/messages")}>Messages</Link>
                        </Badge>
                    </UIGuard>
                    <UIGuard routePerms={{feature: EFeature.Messages, scope: EFeatureScope.Read}}>
                        <Badge>
                            <Link className="text-xl" strong onClick={() => navigate("/messenger")}>Messenger</Link>
                        </Badge>
                    </UIGuard>
                    <UIGuard routePerms={{feature: EFeature.Messages, scope: EFeatureScope.Read}}>
                        <Badge>
                            <Link className="text-xl" strong onClick={() => navigate("/template")}>Template</Link>
                        </Badge>
                    </UIGuard>
                </div>
            </Drawer>
        </Row>
    )
}