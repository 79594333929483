import React from "react";
import FeaturePermType from "../models/FeaturePermType";
import {Checkbox, Typography} from "antd";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import LabelType from "../models/LabelType";
import PermissionType from "../models/PermissionType";

const {Text} = Typography

interface iPermissionSelectGroupProps {
    permList: FeaturePermType[],
    onCheckPerm: (e: CheckboxChangeEvent, scope: { key: string; label: LabelType; }, permName: string) => void,
    rolePermList?: PermissionType[]
}

const PermGroup = ({perm, onCheckPerm, rolePermList} : {
    perm: FeaturePermType,
    rolePermList?: PermissionType[],
    onCheckPerm: (e: CheckboxChangeEvent, scope: { key: string; label: LabelType; }, permName: string) => void },) => {

    const isSelected = (permName: string, scope: {key: string, label: LabelType}) => {
        if (!rolePermList) return false
        const isChecked = rolePermList.some((rolePerm: PermissionType) => {
            if (rolePerm.feature === permName && scope.key === rolePerm.scope) return true
            return false
        })
        return isChecked
    }

    return (
        <div className="flex flex-row mt-2 mb-2">
            <div className="w-24">
                <Text>{perm.name}: </Text>
            </div>
            <div className="space-x-4">
                {perm.scopes.map((scope, index) =>
                {
                    const isChecked = isSelected(perm.name, scope)
                    return <Checkbox key={index}
                                 defaultChecked={isChecked}
                                  onChange={(e: CheckboxChangeEvent) => onCheckPerm(e, scope, perm.name)}
                                  className="w-20">{scope.key}</Checkbox>})}
            </div>
        </div>
    )
}

export const PermissionSelectGroup: React.FC<iPermissionSelectGroupProps> = (props: iPermissionSelectGroupProps) => {
    return (
        <div className="w-min-fit">
            {props.permList.length > 0 ?
                <div>
                    <Text className="font-bold">Set permissions</Text>
                    {props.permList.map((item, index) => <PermGroup onCheckPerm={props.onCheckPerm} key={index} perm={item} rolePermList={props?.rolePermList}/>)}
                </div>
                :
                <div><Text>No permissions found</Text></div>
            }
        </div>
    )
}