enum EFeatureScope {
    Read,
    Comment,
    Create,
    Update,
    Delete,
    Assign,
    Grant
}

export default EFeatureScope