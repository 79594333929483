import { Result, Spin } from "antd";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import {useMsalWrapper} from "../contexts/MSALContext";

export const LoginPage = () => {
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const { safeLogin } = useMsalWrapper();

    const { t } = useTranslation();

    useEffect(() => {
        safeLogin()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inProgress, instance, isAuthenticated])


    return (
        <div className="login">
            <Result status="403" title={t("Logging in...")} extra={<Spin />} />
        </div>
    );
};
