import {PostTemplateType, TemplateType} from "../models/TemplateType";
import {useLocation, useNavigate} from "react-router-dom";
import {Header} from "antd/es/layout/layout";
import {NavBar} from "../components/NavBar";
import {Button, Card, Input, Layout, Radio, Row, Space, Typography} from "antd";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {TitleText} from "../components/TitleText";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import {postNewTemplate} from "../utils/api/AxiosUtils";
import {toast} from "react-toastify";
import {useMsalWrapper} from "../contexts/MSALContext";

const { Link } = Typography;

interface iTemplateDetailPageProp {
    location?: {
        state?: {
            template?: TemplateType
        }
    }
}

const TemplateDetailPage: React.FC<iTemplateDetailPageProp> = (props) => {

    const { acquireToken } = useMsalWrapper()

    const navigate = useNavigate()

    const {t} = useTranslation();

    const [locale, setLocale] = useState<string>(props?.location?.state?.template ? props.location.state.template.locale : 'en');

    const [value, setValue] = useState<string>(props?.location?.state?.template ? props.location.state.template.contents : '');

    const [subject, setSubject] = useState<string>(props?.location?.state?.template ? props.location.state.template.subject : '')

    const [id, setId] = useState<string>(props?.location?.state?.template ? props.location.state.template.id : '')

    const toolbar = [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        [{'color': []}, {'background': []}],
        ['clean']
    ];

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image',
        'color', 'background'
    ];


    const onSubmit = async () => {
        console.log(value)
        if (!value || !id || !subject) return

        const newTemplate: PostTemplateType = {
            id: id,
            contents: value,
            subject: subject,
            locale: locale ? locale : 'en'
        }

        const token = await acquireToken()
        if (!token) return

        const response = await postNewTemplate(token, newTemplate)

        if (!response) {
            toast.error('Failed to create template', {position: "bottom-center", autoClose: 5000, closeOnClick: true})
            return
        }

        toast.success('Successfully created template', {position: "bottom-center", autoClose: 5000, closeOnClick: true})
    }

    return (
        <Layout className="frame">
            <Header id="search-header">
                <NavBar />
            </Header>
            <div className="pageBody">
                <Row id="search-backlink">
                    <Link onClick={() => navigate(-1)} className="text-xl">
                        <Space>
                            <ArrowLeftOutlined />
                            {t("Back to templates")}
                        </Space>
                    </Link>
                </Row>
                <TitleText text={t(`${props?.location?.state?.template ? props.location.state.template.subject : 'Create a new template'}`)}/>
                <div className="flex flex-col">
                    <Card>
                        <div className="mb-3 flex flex-row space-x-3 items-center">
                            <Input
                                addonBefore="Template Title"
                                value={id}
                                onInput={(e) => setId(e.currentTarget.value)}
                                maxLength={30}
                            />
                            <Radio.Group onChange={(e) => setLocale(e.target.value)} value={locale}>
                                <Space direction="horizontal">
                                    <Radio value={'en'}>En</Radio>
                                    <Radio value={'zh'}>Zh</Radio>
                                </Space>
                            </Radio.Group>
                        </div>
                        <div className="mb-3 flex">

                            <Input
                                addonBefore="Subject"
                                value={subject}
                                onInput={(e) => setSubject(e.currentTarget.value)}
                                maxLength={30}
                            />
                        </div>
                        <div>
                            <ReactQuill theme="snow" value={value} onChange={setValue} modules={{ toolbar: toolbar}} formats={formats} />
                        </div>
                        <Button shape="round" size="small" style={{color: 'black'}} className="flex mt-4 -mb-2 ml-auto" onClick={onSubmit}
                        >
                            {t("Save template")}
                        </Button>
                    </Card>
                </div>
            </div>
        </Layout>
    )
}

export const TemplateDetailPageWrapper: React.FC = () => {
    const location = useLocation()

    return <TemplateDetailPage location={location} />
}
