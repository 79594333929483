import { Row, Col, Typography } from "antd";
import { useTranslation } from "react-i18next";
const { Title, Text } = Typography;

export const BackendErrorPage = () => {
    const {t} = useTranslation();
    return (
        <Row>
            <Col className="search-error">
                <Row>
                    <img src={process.env.PUBLIC_URL + "/icons/error.svg"} alt="" />
                </Row>
                <Row>
                    <Title level={3}>{t('Error in the back room')}</Title>
                </Row>
                <Row>
                    <Text>{t('There seems to be something wrong on our end.')}</Text>
                </Row>
                <Row>
                    <Text>{t("Don't worry, we'll figure it out so please try again later")}</Text>
                </Row>
            </Col>
        </Row>
    );
};
