import { MenuProps, Dropdown, Typography, Col } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useMsal } from "@azure/msal-react";
import { useContext } from "react";
import { AccountInfoContext } from "../contexts/AccountInfoContext";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

export const UserDropdown = () => {
    const { t } = useTranslation();
    const accountInfo = useContext(AccountInfoContext);
    // const user: any = useContext(UserContext);
    const { instance } = useMsal();

    // const navigate = useNavigate();

    const signOutClickHandler = () => {
        const logoutRequest = {
            account: accountInfo,
            postLogoutRedirectUri: "/logout",
        };
        instance.logoutRedirect(logoutRequest);
    };

    const items: MenuProps["items"] = [
        {
            key: "1",
            label: <p>{t("Preferences")}</p>,
        },
        {
            key: "2",
            label: <p onClick={() => signOutClickHandler()}>{t("Signout")}</p>,
            icon: <img src={process.env.PUBLIC_URL + "/icons/sign-out.svg"} alt="" />,
        },
    ];

    return (
        <>
            <Col>
                <Dropdown menu={{ items }}>
                    <div className="user">
                        <UserOutlined />
                        <Text className="clickable" strong>
                            {accountInfo?.name}
                        </Text>
                    </div>
                </Dropdown>
            </Col>
        </>
    );
};
