import {User} from "../../contexts/UserInfoContext";
import {getUserByToken} from "./AxiosUtils";

const fetchAllUsers = async (token: string, userIdList: string[]) => {
    const userArr: User[] = []
    for (const userToken of userIdList) {
        const response = await fetchUser(token, userToken)
        if (response) {
            userArr.push(response)
        }
    }
    return userArr
}

const fetchUser = async (token: string, userToken: string) => {
    const response = await getUserByToken(token, userToken)

    if (!response) return

    return response.data
}

export { fetchAllUsers, fetchUser }
