import {Row, Typography} from "antd";
import React from "react";

const {Title} = Typography;

interface iTitleTextProps {
    text: string
}

export const TitleText: React.FC<iTitleTextProps> = (props: iTitleTextProps) => {
    return (
        <Row id="search-title " className="space-x-2 mt-5">
            <div id="box" />
            <Title level={2}>
                {props.text}
            </Title>
        </Row>
    )
}